import { useCallback, useEffect, useState } from 'react'
import {
  usePostMemoAction,
  useUpdateMemoAction,
  useDeleteMemoAction,
  UpdateMemoParams,
  PostMemoParams,
  usePinMemoAction
} from '@commonstock/common/src/api/memo'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { SESSION_PREFIX } from '../../config/constants'
import { clearPersistKey } from '@commonstock/common/src/utils/usePersistedState'
import { TagItemType } from '@commonstock/common/src/types'
import { Tag, useGetItemTags } from '@commonstock/common/src/api/tag'
import { useUpdateTags } from '../profile/EditTags'
import { ShareType, useGetLinkPreviewAction } from '@commonstock/common/src/api/link'

type MemoData = UpdateMemoParams | PostMemoParams
export function useCreateOrUpdateMemo() {
  const postMemo = usePostMemoAction()
  const putMemo = useUpdateMemoAction()

  const createOrUpdateMemo = useCallback(
    (data: MemoData) => {
      // @ts-ignore ts doesnt infer we are in the correct case as it should based on the existence of the uuid property
      return data.json.uuid ? putMemo(data) : postMemo(data)
    },
    [putMemo, postMemo]
  )

  return createOrUpdateMemo
}

export function useDeleteMemo() {
  const deleteMemo = useDeleteMemoAction()

  const removeMemo = useCallback(
    ({ uuid }: { uuid: string }) => {
      return deleteMemo({ meta: { uuid } })
        .then(data => data)
        .catch(err => {
          console.error('## deleteMemo err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [deleteMemo]
  )

  return removeMemo
}

export function useRefetchMemoDependents() {
  const refetchDependents = useRefetchByKeys(
    'memo',
    'trade',
    'get-feed',
    'users-memos',
    'asset-trades',
    'user-trades',
    'get-tagged-memos',
    'get-global-feed'
  )
  return refetchDependents
}

export const DRAFT_TITLE_KEY = SESSION_PREFIX + 'memo-draft-title'
export const PERSIST_BODY_KEY = SESSION_PREFIX + 'memo-draft-body'
export const DRAFT_LINK_KEY = SESSION_PREFIX + 'memo-draft-link'
const DRAFT_TAGS_KEY = SESSION_PREFIX + 'memo-draft-tags'

export function clearMemoPersistedKeys() {
  clearPersistKey(DRAFT_TITLE_KEY)
  clearPersistKey(PERSIST_BODY_KEY)
  clearPersistKey(DRAFT_TAGS_KEY)
  clearPersistKey(DRAFT_LINK_KEY)
}

type UseEditMemoTagsProps = {
  parentUuid?: string
  parentType: TagItemType
}
export function useEditMemoTags({ parentUuid = '', parentType }: UseEditMemoTagsProps) {
  const isNew = !parentUuid
  const [fetchTags] = useGetItemTags({ meta: { uuid: parentUuid } }, { paused: !parentUuid })
  const [tags, setTags] = useState<Tag[]>(JSON.parse((isNew && localStorage.getItem(DRAFT_TAGS_KEY)) || '[]'))

  useEffect(() => {
    fetchTags && setTags(fetchTags)
  }, [fetchTags, setTags])

  useEffect(() => {
    parentUuid && localStorage.setItem(DRAFT_TAGS_KEY, JSON.stringify(tags))
  }, [tags, parentUuid])

  const updateTags = useUpdateTags()

  const saveTags = useCallback(async (uuid: string) => await updateTags(fetchTags || [], tags, uuid, parentType), [
    tags,
    fetchTags,
    parentType,
    updateTags
  ])

  const removeTag = useCallback(
    (tag: Tag) => {
      tags.splice(
        tags.findIndex(t => t.canonical_name === tag.canonical_name),
        1
      )
      setTags([...tags])
    },
    [tags, setTags]
  )

  return { tags, setTags, saveTags, removeTag }
}

export function useUpdateLinkPreview() {
  const fetchLinkPreview = useGetLinkPreviewAction()

  const updateLinkPreview = useCallback(
    (type: ShareType.MEMO_PLUS | ShareType.TRADE, uuid: string, skip_cache: boolean) => {
      fetchLinkPreview({ meta: { type, uuid }, query: { skip_cache } }).catch(fail => {
        // Silently fails
        console.error('## could not create share link', fail)
      })
    },
    [fetchLinkPreview]
  )

  return updateLinkPreview
}

export function usePinMemo() {
  const pinMemo = usePinMemoAction()
  const pinnedMemo = useCallback(
    ({ uuid, pinned }: { uuid: string; pinned: boolean }) => {
      return pinMemo({ meta: { uuid }, json: { pinned } })
        .then(data => data)
        .catch(err => {
          console.error('## pinMemo err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [pinMemo]
  )
  return pinnedMemo
}
