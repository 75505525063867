import React, { useCallback, useState } from 'react'
import { More } from '../../theme/NewIcons'
import { IconButton } from '../../composing/CSButton'
import { Menu, MenuItem } from '../../components/styles'
import useEventListener from '@commonstock/common/src/utils/useEventListener'
import { Routes } from '../nav/constants'
import { useGoto } from '../nav/utils'
import FollowProfile from '../profile/FollowProfile'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { ArchivedContentType } from '@commonstock/common/src/types'
import { MuteToggleItem } from '../profile/ProfileButtons'
import { useAuth } from '../auth/AuthContext'
import ReportContentMenuItem from '../../components/ReportContent'
import Unarchive from '../../components/Unarchive'
import {
  ContentTypeLabels,
  FeedItem,
  FeedReason,
  FeedResourceType,
  isMemoFeedItem,
  isPostFeedItem,
  MemoFeedItem,
  PostFeedItem
} from '@commonstock/common/src/api/feed'
import { ModalScope, useEphemeralModal, useModal } from '../modal/Modal'
import dynamic from 'next/dynamic'
import EditPost from 'src/scopes/posts/EditPost'
import { destructPostFeedItem } from 'src/scopes/posts/utils'
import { destructContentFeedItem, getContentShareType, getContentReportType } from 'src/scopes/content/utils'
import { useRefetchPostDependents } from 'src/scopes/posts/postHooks'

const ShareModal = dynamic(() => import('../share/ShareModal'))
const DeleteMemo = dynamic(() => import('../memo/DeleteMemo'))
const DeletePost = dynamic(() => import('../posts/DeletePost'))
const PinContent = dynamic(() => import('src/scopes/content/PinnedContent'))

function useGoToEditContent(
  feedItem: PostFeedItem | MemoFeedItem,
  onFinish?: (item: FeedItem) => void,
  detail?: boolean
) {
  let editMemo = useGoto(Routes.editMemo(feedItem.uuid))
  const modal = useEphemeralModal()
  const editContent = useCallback(async () => {
    if (isMemoFeedItem(feedItem)) {
      editMemo()
    } else if (isPostFeedItem(feedItem)) {
      const { post } = destructPostFeedItem(feedItem)
      modal(<EditPost postFeedItem={feedItem} onFinish={onFinish} />, {
        ...(!detail ? { shallowUrl: Routes.postDetail(post?.uuid) } : {}),
        scope: ModalScope.Ephemeral
      })
    }
  }, [detail, editMemo, feedItem, modal, onFinish])

  return editContent
}

type Props = {
  feedItem: MemoFeedItem | PostFeedItem
  detail?: boolean
}
function ContentCardActions({ feedItem, detail }: Props) {
  const modal = useEphemeralModal()
  const { activeModal, actions } = useModal()
  const { userUuid, isModerator } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const refetchPostDependents = useRefetchPostDependents()
  const onPostFinish = useCallback(
    (item: FeedItem) => {
      if (item) {
        refetchPostDependents()
        if (detail) {
          actions.close()
        } else {
          modal(null)
        }
      }
    },
    [actions, detail, modal, refetchPostDependents]
  )
  let { content, user } = destructContentFeedItem(feedItem)

  const goToEditContent = useGoToEditContent(feedItem, onPostFinish, detail)

  const [userProfile] = useGetProfile({ meta: { user_uuid: content?.user_uuid || '' } }, { paused: !isOpen })

  user = userProfile ? { ...userProfile, uuid: userProfile.user_uuid } : user

  const onClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }, [])

  const isMyContent = userUuid === content?.user_uuid
  useEventListener('click', (e: any) => !e.target.closest(`#actions-${content?.uuid}`) && setIsOpen(false))

  const onClickEdit = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      goToEditContent()
    },
    [goToEditContent]
  )

  const handleDeleteContent = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      if (isMemoFeedItem(feedItem)) {
        const memoDetailMatch = window.location.href.match(Routes.memoDetail(content?.uuid))
        const editMemoMatch = window.location.href.match(Routes.editMemo(content?.uuid))
        const memoRoute = !!memoDetailMatch || !!editMemoMatch
        modal(<DeleteMemo uuid={content?.uuid || ''} memoRoute={memoRoute} shallowRoute={memoRoute && !!activeModal} />)
      }
      if (isPostFeedItem(feedItem)) {
        const postDetailMatch = window.location.href.match(Routes.postDetail(content?.uuid))
        modal(
          <DeletePost
            uuid={content?.uuid || ''}
            postRoute={!!postDetailMatch}
            shallowRoute={!!postDetailMatch && !!activeModal}
          />
        )
      }
    },
    [activeModal, content, feedItem, modal]
  )

  const handlePinContent = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      const { type } = destructContentFeedItem(feedItem)
      if (type === FeedResourceType.MEMO_PLUS) {
        const memoDetailMatch = window.location.href.match(Routes.memoDetail(content?.uuid))
        const editMemoMatch = window.location.href.match(Routes.editMemo(content?.uuid))
        const memoRoute = !!memoDetailMatch || !!editMemoMatch
        modal(
          <PinContent
            uuid={content?.uuid || ''}
            type={type}
            contentRoute={memoRoute}
            shallowRoute={memoRoute && !!activeModal}
            pinned={feedItem.reason_code === FeedReason.Pinned}
          />
        )
      }
      if (type === FeedResourceType.POST) {
        const postDetailMatch = window.location.href.match(Routes.postDetail(content?.uuid))
        modal(
          <PinContent
            uuid={content?.uuid || ''}
            contentRoute={!!postDetailMatch}
            type={type}
            shallowRoute={!!postDetailMatch && !!activeModal}
            pinned={feedItem.reason_code === FeedReason.Pinned}
          />
        )
      }
    },
    [activeModal, content, feedItem, modal]
  )

  const handleShare = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      modal(<ShareModal type={getContentShareType(feedItem)} uuid={content?.uuid || ''} />)
    },
    [content, feedItem, modal]
  )
  if (!content || !user) return null
  return (
    <div id={`actions-${content.uuid}`}>
      <IconButton onClick={onClickMenu} aria-label={`Show more ${ContentTypeLabels[feedItem.resource_type]} actions`}>
        <More active={isOpen} size={1.25} />
      </IconButton>
      <Menu hidden={!isOpen}>
        <MenuItem disabled={user.private} onClick={handleShare}>
          {user.private ? 'Share disabled' : `Share ${ContentTypeLabels[feedItem.resource_type]}`}
        </MenuItem>
        {isMyContent ? (
          <>
            <MenuItem onClick={onClickEdit}>Edit {ContentTypeLabels[feedItem.resource_type]}</MenuItem>
            <MenuItem isDestructive onClick={handleDeleteContent}>
              Delete {ContentTypeLabels[feedItem.resource_type]}
            </MenuItem>
            {isModerator && (
              <>
                <MenuItem isModerator onClick={handlePinContent}>
                  {feedItem.reason_code === FeedReason.Pinned ? 'Unpin' : 'Pin'}{' '}
                  {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
              </>
            )}
          </>
        ) : (
          <>
            <FollowProfile profile={user} isDropdown />
            <MuteToggleItem profile={user} />
            {isMemoFeedItem(feedItem) && (
              <ReportContentMenuItem
                uuid={content.uuid}
                title={(content as any).title || ''}
                contentType={getContentReportType(feedItem)}
              />
            )}
            {isModerator && (
              <>
                <MenuItem isModerator onClick={onClickEdit}>
                  Edit {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
                {isMemoFeedItem(feedItem) &&
                  (content.archived_at ? (
                    <Unarchive uuid={content.uuid} contentType={ArchivedContentType.MEMO_PLUS} />
                  ) : (
                    <MenuItem isModerator onClick={handleDeleteContent}>
                      Archive {ContentTypeLabels[feedItem.resource_type]}
                    </MenuItem>
                  ))}
                <MenuItem isModerator onClick={handlePinContent}>
                  {feedItem.reason_code === FeedReason.Pinned ? 'Unpin' : 'Pin'}{' '}
                  {ContentTypeLabels[feedItem.resource_type]}
                </MenuItem>
              </>
            )}
          </>
        )}
      </Menu>
    </div>
  )
}

export default ContentCardActions
