import { useCallback } from 'react'
import {
  useDeletePostAction,
  usePostPostAction,
  useUpdatePostAction,
  UpdatePostParams,
  PostPostParams,
  usePinPostAction
} from '@commonstock/common/src/api/post'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'

type PostData = UpdatePostParams | PostPostParams
export function useCreateOrUpdatePost() {
  const postPost = usePostPostAction()
  const putPost = useUpdatePostAction()

  const createOrUpdatePost = useCallback(
    (data: PostData) => {
      // @ts-ignore ts doesnt infer we are in the correct case as it should based on the existence of the uuid property
      return data.json.uuid ? putPost(data) : postPost(data)
    },
    [putPost, postPost]
  )

  return createOrUpdatePost
}

export function useDeletePost() {
  const deletePost = useDeletePostAction()
  const removePost = useCallback(
    ({ uuid }: { uuid: string }) => {
      return deletePost({ meta: { uuid } })
        .then(data => data)
        .catch(err => {
          console.error('## deletePost err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [deletePost]
  )
  return removePost
}

export function useRefetchPostDependents() {
  const refetchDependents = useRefetchByKeys(
    'post',
    'trade',
    'get-feed',
    'users-memos',
    'users-posts',
    'asset-trades',
    'user-trades',
    'get-tagged-memos',
    'get-global-feed'
  )
  return refetchDependents
}

export function usePinPost() {
  const pinPost = usePinPostAction()
  const pinnedPost = useCallback(
    ({ uuid, pinned }: { uuid: string; pinned: boolean }) => {
      return pinPost({ meta: { uuid }, json: { pinned } })
        .then(data => data)
        .catch(err => {
          console.error('## pinPost err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [pinPost]
  )
  return pinnedPost
}
