import { createUseFetchAction, createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, CacheType } from '@commonstock/client/src/constants'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import { MemoFeedItem, MemoFeedOutput } from './feed'
import { AssetMention, ProfileMention } from '../types/mentions'

export const BASE_MEMO_PATH = '/feed/v3/memo/'
type MemoParams = { meta: { uuid: string } }
export const useGetMemo = createUseFetch<MemoFeedItem, MemoParams>({
  key: 'memo',
  path: ({ meta: { uuid } }) => `${BASE_MEMO_PATH}${uuid}`
})

export type PostMemoParams = {
  json: {
    title: string
    body: string
    media: string[]
  }
}

// This Schema is uncomplete, but we keep it because we only use the uuid
export type PostMemo = {
  asset_mentions: AssetMention[]
  user_mentions: ProfileMention[]
  summary: string
  title: string
  uuid: string
}
export const usePostMemoAction = createUseFetchAction<PostMemo, PostMemoParams>({
  key: 'memo-create',
  method: HttpMethods.Post,
  path: '/user-content/memos/create'
})

export type UpdateMemoParams = {
  json: {
    uuid: string
    title: string
    body: string
    media: string[]
  }
}

export const useUpdateMemoAction = createUseFetchAction<PostMemo, UpdateMemoParams>({
  key: 'memo-update',
  method: HttpMethods.Put,
  path: ({ json: { uuid } }) => `/user-content/memos/${uuid}`
})

type MemoDeleteParams = { meta: { uuid: string } }
export const useDeleteMemoAction = createUseFetchAction<string, MemoDeleteParams>({
  key: 'memo-delete',
  method: HttpMethods.Delete,
  path: params => `/user-content/memos/${params.meta.uuid}`
})

export const useUnarchiveMemoAction = createUseFetchAction<void, MemoDeleteParams>({
  key: 'memo-unarchive',
  method: HttpMethods.Post,
  path: params => `/user-content/memos/${params.meta.uuid}/unarchive`
})

type MemoPinnedParams = {
  meta: {
    uuid: string
  }
  json: {
    pinned: boolean
  }
}

export const usePinMemoAction = createUseFetchAction<string, MemoPinnedParams>({
  key: 'memo-pin',
  method: HttpMethods.Post,
  path: params => `/user-content/v2/memos/${params.meta.uuid}/pinned`
})

const pagerOptions: PagerOptions<MemoFeedOutput, any> = {
  getCursor: (page, resource) => {
    let list = resource?.success?.payload?.items
    let last = list && list[list.length - 1]
    return { query: { limit: 10, after_uuid: last?.uuid } }
  },
  // @NOTE: checking payload since BE is returning null for empty lists
  isTerminal: resource =>
    !!(resource.success && (!resource.success.payload || resource.success.payload.items.length < 10))
}

type UserMemoParams = {
  query: {
    target_uuid: string
    before_uuid?: string
    after_uuid?: string
    limit?: number
  }
}
export const useGetUsersMemos = createUsePagedFetch<MemoFeedOutput, UserMemoParams>(
  {
    key: 'users-memos',
    path: '/feed/v3/user-memos',
    cache: CacheType.Disk
  },
  pagerOptions
)
